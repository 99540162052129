
/* @import url('https://fonts.googleapis.com/css2?family=Dosis&family=Varela+Round&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Dosis&family=Noto+Sans:wght@500&family=Roboto+Slab:wght@600&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Nunito:wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600;700;800&family=Dosis:wght@200;300;400;500;600;700;800&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700&display=swap');

*{
    font-family: 'Nunito', sans-serif;  
}
@media only screen and (max-width:425px)
{
    .home_bg_img
    {
        height:90vh !important;
    }
    .service_owl .owl-dots
    {
        margin-top: unset !important;
    }
    .panel
    {
        padding: 20px 0px !important;
    }
    .port_btn
    {text-align: center;}
    .con_align {
        margin-top: 22px;
    }
    .ser_height_set
    {
        height:unset !important;
    }

    .panel,.height_set
    {
        height:unset !important;
    }
    .pro_head
    {
        text-align: center;
    }
    .copyright,.bytagtes
    {
        text-align: center !important;
    }
    .about_paddy
    {
        padding: 20px 0px;
    }
    .testi_box_align,.bg_img
    {
        height:600px !important;
    }
    .banner_para_align
    {
        position: unset !important;
        margin-top: 70px;
        text-align: center;
    }
    .portfolio_height
    {
        height:unset !important;
    }
    .nav-item
    {
    padding: 10px;
    }
    .tab_paddi
    {
        padding: unset !important;
        border-radius: 10px !important;
    }
    .logo_img
    {
        width:228px !important;
    }
    .nav_padding
    {
        padding: 0px 10px 0px 10px !important;
    }
    .section_height_set
    {
        height:unset !important;
    }
    .banner_image
    {
        width:280px !important;
        height:auto !important;
        margin-left: unset !important;
    }
    .banner_bg_set
    {
        height:unset !important;
        padding: 12px;
    }
    .banner_height_set
    {
        height:80vh !important;
    }
    .banner_owl .owl-dots
    {
        margin-top:unset !important;
    }
    .header_align {
    
        top: 15% !important;
        
        transform: translate(2%, -50%) !important;
        
    }
    .header_align2
    {
        top: 18% !important;
         padding-left: unset !important; 
         padding-top: unset !important; 
         transform: translate(1%, -50%) !important;
    }
    .header_align3 {
        color: #fff;
        position: relative;
        top: 25% !important;
         padding-left: unset !important; 
         margin-top: unset !important; 
        transform: translate(0%, -50%);
        font-weight: 500;
        text-align: center;
    }
    .sec_heading,.sec_sub_heading
    {
        text-align: center;
    }
    .overlay_heading 
    {
        display: none;
    }
    .select_us_heading
    {
        text-align: center !important;
    }
    .select_para_align_left
    {
        padding-right:unset !important;
    }
    .select_para_align_right
    {
        padding-left:unset !important;
    }
    .carousel-caption
    {
        padding-top:3.25rem !important;
    }
    .about_div_align
    {
            padding-left: unset !important;
            padding-right: unset !important;
    }
    .about_heading
    {
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 12px;
    }
    .box-align {
        margin-top: unset !important; 
         margin-left: unset !important;
    }
    .box_1, .box_2, .box_3, .box_4
    {
        width:unset !important
    }
    .paddi_size_20
    {
        padding: 0px 10px !important;
    }
    .portfolio_header
    {
        padding: unset !important;
    }
    .work_img_size
    {
        width:300px !important;
    }
    .work_padding
    {
        padding-top:30px ;
    }
    .Discover
    {
        text-align: center;
    }
    .discover_para
    {
        text-align: center;
        padding-bottom: 20px;
        font-size: 14px;
    }
    .navbar,.banner_para_align
    {
        padding: 10px;
    }
    .bg-img
    {
        height:90vh !important;
    }
    .carousel_align
    {
        padding: 12px 0px !important;
        text-align:center;
    }
    .owl-theme .owl-nav
    {
        display: none;
    }
    .caro_content h2
    {
        text-align: center;
    }
    .navbar-nav
    {
        background-color: #f6d6d6;
        padding-bottom: 10px;
    }
    .about_chronicle h2
    {
        text-align: center;
    }
    .sec_sub_heading
    {
        /* font-weight: 600; */
        font-size: 14px;
        color:#202020 !important;
    }
    .alg_para_align
    {
        padding-top: unset !important;
        font-size: 27px !important;
    }
    .port_img_align img
    {
        position: unset !important;
    }
    .Port_box_align
    {
        padding: 10px !important;
        height:unset !important;
        border-radius: 10px !important;
    }
    .sec_align {
        margin-top: 40px !important;
    }
    .section_bgcolor_1, .section_bgcolor_2, .section_bgcolor_3, .section_bgcolor_4, .section_bgcolor_5, .section_bgcolor_6
    {
        height:unset !important;
    }
    .ser_bg_color
    {
        background-image: linear-gradient( #F6D6D6, #F6D6D6 );
        padding: 10px !important;
        border-radius: 10px;
    }
    .section_bgcolor
    {
        height:unset !important;
        background-image: unset !important;
    }
    .about_h2
    {
        font-size: 30px !important;
    }
    .owl_align,.owl-dots
    {
        /* display: none; */
    }
    .app_align
    {
        font-weight: 700;
        color:#000 !important;
        text-align: center !important;
    }
    .c_box1, .c_box2, .c_box3, .c_box4
    {
        height:unset !important;
    }
    .c_box1
    {
        margin-top: unset !important;
    }
    .caro_content p
    {
        height:unset !important;
    }
    .caro_content h2
    {
        font-size: 30px;
    }
    .home_align p
    {
        text-align: center !important;
    }
    .owl-carousel .owl-item img
    {
        margin-top: unset !important;
    }
    .nav
    {
        /* border-left: 3px solid #fff; */
        border-right: unset !important;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link
    {
        border-left: 3px solid #A00;
        border-right: unset !important;
    }
    .gooey:before, .gooey:after
    {
        width:130% !important;
        height:130% !important;
        top:0px !important;
    }
    .height_set
    {
        /* height: 50vh !important; */
    }
}
@media only screen and (max-width:1024px){
    .testi_box_align,.bg_img
        {
            height:350px !important;
        }}
@media only screen and (max-width:768px){
.testi_box_align,.bg_img
    {
        height:auto !important;
    }}
@media only screen and (max-width:425px){
    .testi_box_align,.bg_img
        {
            height: auto !important;
        }}
@media only screen and (min-width:768px) and (max-width:990px)
{
    .nav-item
    {
        padding: 10px 0px;
    }
    .navbar-nav {
        background-color: #f6d6d6;
        padding-bottom: 10px;
    }
    .ser_heading,.ser_para,.section_bgcolor
    {
        height:unset !important;
    }
    .overlay_heading
    {
        font-size: 100px !important;
    }
    .bg_img
    {
        height:auto !important;
    }
    .home_h2
    {
        font-size: 30px !important;
    }
    .select_para_align_left
    {
        padding-right: unset !important;
    }
    .select_para_align_right
    {
        padding-left: unset !important;
    }
    .gooey
    {
        width: 100px !important;
        height: 74px !important;
    }
    .gooey:before, .gooey:after
    {
        top: 20px !important;
    }
    .alg_para_align
    {
        padding-top:70px !important;
        font-size: 30px !important;
    }
    .bg-img
    {
        height:456px !important;
    }
    .ser_sidebg
    {
        background-size: 58%;
    }
    .panel
    {
        height:unset !important;
    }
    .height_set
    {
        height: unset !important;
    }
    .banner_height_set
    {
        height:80vh !important;
    }
    .home_bg_img
    {
        height:85vh !important;
    }
    .Port_box_align
    {
        padding: 20px !important;
    }
    .Port_box_align img
    {
        width:70% !important;
        margin-left: auto;
        margin-right: auto;
    }
    .Port_box_align h2,.app_align,.port_align
    {
        text-align: center !important;
    }
    .career_content p
    {
        height:24vh !important;
    }
    .service_owl .owl-dots
    {
        margin-top: unset !important;
    }
    .con_align
    {
        margin-top:80px;
    }
   
}
p{
    line-height: 28px;
    letter-spacing: 1px;
    text-align: justify;
}
html,body{height:100%}

.section_height_set
{
    height: 100vh;
    margin-top: -78px;
    
}
.nav_padding
{
    padding:0px 30px 0px 30px;
}
.banner_bg_set
{
    height: 100vh;
    background-color:#AA0000;
    
}
.header_align
{
    color:#fff;
    position: relative;
    top: 45%;
    padding-left: 26px;
    transform: translate(0%, -50%); 
    font-weight: bold;

}
.header_align2
{
    color:#fff;
    position: relative;
    top: 45%;
    padding-left: 94px;
    padding-top: 30px;
    
    transform: translate(0%, -50%); 
    font-weight: bold;

}
.header_align3
{
    color:#fff;
    position: relative;
    top: 45%;
    padding-left: 196px;
    margin-top: -10px;
    transform: translate(0%, -50%); 
    font-weight: 500;

}
.navbar-expand-lg .navbar-nav .nav-link
{
         padding-left: 0px;
}
.menu_bg ul li a
{
    color:#000;
    font-weight: 600;
    font-size: 14px;
    padding-right: 12px !important;
    margin: -6px 5px;
}
.cont-btn
{
    background-color: rgb(170, 0, 0) !important;
    border-radius: 30px;
    color:#fff !important;
    padding-top: 5px;
    /* padding-right: 0px !important; */
   
}

.sec_heading
{
    font-weight: 600;
    color:#000;
    padding: 0px !important;
    margin-bottom: 0px !important;
}
.sec_sub_heading
{
    color: #505050;
}
.overlay_heading
{
    font-size: 130px;
    color:#EFEFEF;
    font-weight: 600;
    text-align: right;
    /* padding-left: 74px; */
    margin-top: -50px;
}
.home_img
{
    position:absolute;
    top:20%;
}
.carousel-caption {
    position: unset;
    right:0%;
    bottom: 1.25rem;
    left: 0%;
    /* padding-top: 1.25rem;
    padding-bottom: 1.25rem; */
    color: #000;
    text-align: center;
}

.nav
{
    border-right: 3px solid #fff;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link
{
    border-right: none;
    border-radius: 0px;
    box-shadow: none;
}
.select_us_heading
{
    font-weight: 700;
    color:#a00;
    text-align: left;
}
.select_para_align_left
{
    padding-right: 75px;
}
.select_us_para
{
    color: #3e3e3e;
    line-height: 1.5;
    word-spacing: 5px;
    letter-spacing: 1.3px;
    text-align: justify;
    
    
}
.select_para_align_right
{
    padding-left: 75px;
}
.select_us_para_right
{
    color: #3e3e3e;
    line-height: 1.5;
    /* word-spacing: 5px; */
    letter-spacing: 1.3px;
    text-align: justify;
    
}
.carousel-indicators .active {
    opacity: unset !important;
    background-color: #AA0000 !important;
}
.carousel-indicators 
{
    margin-bottom: -2rem;
}
.carousel-indicators [data-bs-target]
{
    background-color: #b14c4c;
    width: 10px;
    height: 10px;
}
.about_sec_align
{
    background-color: #AA0000;
    opacity: 0.8;
    z-index: -1;
}
.sec_align
{
    margin-top:20px ;
}
.about_div_align
{
    padding-left: 100px ;
    padding-right: 60px ;
    padding-top: 30px;
    padding-bottom: 30px;
}
.about_sec_align
{
    background-color: #AA0000;
}
.about_heading
{
    color:#fff;
    font-weight: bold;
}
.about_para
{
    color:#FFFDFD;
    text-align: justify;
    font-size: 14px;
}
.about_btn_align
{
    background-color: #fff;
    border-radius: 30px;
    color:#000;
    border-color: #fff;
    font-weight: 600;
    padding: 5px 20px;
}
.box_1,.box_2,.box_3,.box_4
{
    width:200px;
    height:150px;
    border-radius: 15px;
    text-align: center;
    padding: 20px 0px;
    margin-top: 25px;
}
.box_1,.ser_box_1,.about_box1,.section_bgcolor_1,.c_box1,.contact_box,.pro_align_1,.pro_align_6
{
    background-image: linear-gradient(to right, #F6D6D6, #F6D6D6 );
}
.box_2,.ser_box_2,.about_box2,.section_bgcolor_2,.c_box4,.pro_align_2,.pro_align_5
{
    background-image: linear-gradient(to right, #C9E8DB, #C9F4E2 );
    
}
.box_3,.ser_box_3,.about_box3,.section_bgcolor_3,.c_box3,.pro_align_3
{
    background-image: linear-gradient(to right, #C3D8F6, #CFE3FF );
}
.box_4,.ser_box_4,.about_box4,.section_bgcolor_4,.c_box2,.pro_align_4
{
    background-image: linear-gradient(to right, #E6DDF4, #EEE5FD );
}
.count_icon
{
    font-size: 20px;
    font-weight: bold;
}
.count
{
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
}
.count_para
{
    color:#505050;
}
.box-align
{
    margin-top: -264px;
    margin-left: -60px;
}
.work_sec_align
{
    margin-top:20px;
}


  .scrollspy {
    margin: 10px;
    /* position: fixed; */
    top: 0;
    left: 0;
  }
 
  .isCurrent a {
    font-weight: bold;
    color:#AA0000;
  }
  
.scrollspy_height
{
    height: fit-content;
    /* padding: 20px 0px; */

}
.test_1
{
    width:350px;
    height:350px;
}
.Discover
{
    font-weight: bold;
}
.discover_para
{
    color:#505050;
}
.portfolio_height
{
    
    background-image: linear-gradient( #F6D6D6, #F6D6D6 );
    box-shadow: inset 0 0 10px #0000003f;
    /* opacity: 0.8; */
}
.portfolio_height_div
{
    padding: 38px 0px;
}
.nav_link_tab
{
    font-weight: bold;
    color:#000;
    line-height: 2;
}
.nav-pills .nav-link.active {
    color: none;
    background-color: transparent;
    border-right: 3px solid #A00;
}
.tab-content>.active
{
    display: block;
    background: #fff;
}
.nav-pills .nav-link:hover
{
    color: #A00;
}
a {
    display: inline-block;
    color: #000;
    text-decoration: none;
  }
  .nav_border::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #A00;
    transition: width .4s ease-in;
  }
  
  a:hover::after {
    width: 100%;
  }
.owl-nav
{
    display: none;
}
.portfolio_header
{
    color:#000;
    font-weight: bold;
    
    padding: 10px 30px;
}
.portfolio_box
{
    background-color: #fff;
    border-radius: 15px;
    padding-bottom: 1rem;
    padding-top: 1rem;

}
.portfolio_img
{
    text-align: center;
}
.bg_ser
{
    height:80vh;
    background-color: #AA0000;
    opacity: 0.8;
}
.ser_box_1,.ser_box_2,.ser_box_3,.ser_box_4
{
    width: auto;
    border-radius: 15px;
    box-shadow: 5px 5px 8px 1px rgb(201, 201, 201);
    text-align: center;
    padding: 10px;
}
.ser_img_align
{
    width:190px;
}
.ser_align
{
    padding-top: 30px;
}
.ser_heading
{
    font-size: 18px;
    font-weight: 600;
    height:45px;
}
.ser_para
{
    font-size: 16px;
    /* padding: 0px 10px; */
    color:#3e3e3e;
    height:165px;
    text-align: justify;
}
.ser_link
{
    color:#AA0000;
    font-weight: bold;
    /* border: 2px solid #AA0000; */
}
.ser_link:hover{
    color: #AA0000;
}
.bg_img
{
    /* background-image: url("./assets/img/feed.png");
    height:75vh;
    padding: 0px;
    opacity: 0.2; */

    background-image: url("./assets/img/feed.png");
    background-size: cover ;
    background-position: center ;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    /* height:68vh; */
    z-index: 0;
    
}

.samp
{
    width: 100%;
   /* padding-top:35px ; */
   /* padding-bottom: 10px; */
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
}
.banner_image
{
    width:500px;
    height:500px;
    margin-top: 125px;
    margin-left: 40px;
}
.work_img_size
{
    width: 500px;
}

.bg-img
{
    background-image: url("./assets/img/bg.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 60%;
    height:80vh;
}
.about_banner
{
    /* padding-top:50px; */
}
.about_img_align
{
    width: 100%;
}
.about_head_para
{
    font-size: 20px;
    color:#000;
    font-weight: 700;
}
.about_h2
{
    color:#a00;
    font-size: 40px;
    font-weight: 700;
}
.col_align
{
    position: relative;
}
/* .banner_para_align
{
    position: absolute;
    top:50%;
} */
.navbar
{
    position: fixed;
    width: 100%;
    z-index: 2;
}
.about_box1,.about_box2,.about_box3,.about_box4
{
    width:100%;
    height:auto;
    border-radius: 5px;
    padding: 30px;
    text-align: center;
}
.about_count_para
{
    margin-bottom: 0px;
    color:#6a6a6a;
}
.about_count
{
    font-weight: 700;
}
.section_align
{
    padding:60px 0px 40px 0px;
}
.about_chronicle h2
{
    font-weight: 700;
}
.about_chronicle p
{
    color:#6a6a6a;
    text-align: justify;
}
.sec_bg_img
{
    padding: 50px 0 50px;
    background-image: url(./assets/img/subbanner.png) ;
    background-size: cover ;
    background-position: center ;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 0;
}

.overlay_about
{
    background-image: linear-gradient( #f6d6d6e8, #f6d6d6);
    top:0%;
    width:100%;
    height:100%;
    position:absolute;
    z-index: -1;
    
}
.joinus
{
    color:#A00;
    font-weight: 700;
    text-align: center;
    /* position: absolute; */
}
.join_btn
{
    color:#FFF;
    background-color: #a00;
    border :unset ;
    margin-top:10px;
    padding: 5px 30px;
    border-radius: 30px;
    font-weight: 700;

}
.about_home_align
{
    color: #343434;
    line-height: 1.5;
    word-spacing: 5px;
    letter-spacing: 1.3px;
    text-align: justify;
}
.about_home_head,.count_home
{
    color:#000;
    font-weight: 700;
}
.alg_para_align
{
    font-size:34px;
    font-weight: 600;
    padding-top: 180px;
    color: #000;
}
.alg_btn
{
    color:#fff;
    background-color: #a00;
    border :unset ;
    padding: 5px 30px;
    border-radius: 30px;
    font-weight: 700;
}
.section_bgcolor
{
    height:100vh;
    background-color: #F6D6D6D1 ;
    box-shadow: inset 0px 0px 12px 0px rgb(0 0 0 / 10%);
}
.section_bgcolor_1,.section_bgcolor_2,.section_bgcolor_3,.section_bgcolor_4,.section_bgcolor_5,.section_bgcolor_6
{
    height:100vh;
}

.caro_content h2
{
    color:#a00;
    font-weight:700;

}
.career_content p
{
    color: #3e3e3e;
    line-height: 1.5;
    word-spacing: 5px;
    letter-spacing: 1.3px;
    text-align: justify;
    height:20vh;
}
.caro_content p
{
    color: #3e3e3e;
    line-height: 28px;
    letter-spacing: 1px;
    text-align: justify;
    /* height:20vh; */
}
.Port_box_align
{
    padding: 50px;
    background-image: linear-gradient( #F6D6D6, #F6D6D6 );
    border-radius: 25px;
    width:100%;
}
.port_img_align
{
    position: relative;
    margin-top:8%;
}
.port_img_align img
{
    position: absolute;
    margin-top: 0px;
    width: 80% !important;
}
@media only screen and (max-width:1024px){
    .port_img_align img {
        position: absolute;
        margin-top: 60px;
        width: 100% !important;
    }
}
.owl-carousel.owl-loaded
{
    z-index: 0;
}
.owl-theme .owl-nav
{
    /* display: none; */
}
.owl-theme .owl-dots .owl-dot span
{
    margin: 15px 7px !important;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span
{
    background-color: #a00 !important;
}
.owl-prev,.owl-next
{
    padding: 0 !important;
    font: inherit;
    width: 30px !important;
    background-color: #A00 !important;
    height: 30px !important;
    border-radius: 50% !important;
    color: #fff !important;
}
.owl-theme .owl-nav
{
    text-align: left !important;
}
.tick_icon{
    color:#00AF66;
}
.contacts_icon{
    color: #fab8c4
}
.thear_align
{
    background-color: #a00;
    color: #ffffff;
}
.table>:not(caption)>*>* 
{
    padding: 0.3rem 0.3rem;
}
.career_content h5
{
    font-weight: 700;
    font-size: 16px;
    text-align: start;
    line-height: 15px;
    color: #0000004d;
}
.career_content h3
{
    font-weight: 700;
    text-align: start;
}

.c_box1,.c_box2,.c_box3,.c_box4
{
    border-radius: 15px;
    padding: 20px;
    /* height:100vh; */
}
.font-size-14
{
    font-size: 14px;
}
.contact_box
{
    border-radius: 15px;
    padding: 30px;
    /* margin-bottom: 97px; */
}
.form-control
{
    border-radius: 26px;
}
.form-label
{
    padding-left: 6px;
    font-size: 18px;
}
.contact_box h2
{
    font-weight: 700;
}
a
{
    text-decoration: unset;
}

/* nav */
.menu_icon
{
    font-size: 30px;
    color:#a00;
}
.navbar-toggler:focus
{
    text-decoration: none;
    outline: unset;
    box-shadow: unset;
}
.youtube_align
{
    width:100%; 
    height:315px;
}
.about_content_align
{
    /* height:90vh; */
    background-image: linear-gradient( #F6D6D6, #F6D6D6 );   
    padding: 30px 0px;
    text-align: justify;
    box-shadow: inset 0px 0px 12px 0px rgb(0 0 0 / 10%);
}
.home_bg_img
{
    
    background-image: url("./assets/img/home.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
    height:100vh;
}
.home_h2
{
    font-size: 45px;
    font-weight: 700;
    color:#000;
}
.home_align p
{
    font-size: 20px;
    color:#000
}
.col_align
{
    /* height:100vh; */
}
.count_home h3
{
    font-weight: 700;
}
.testi_box_align
{
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0.15rem 1rem rgb(0 0 0 / 15%);
    height: 300px;
}
.overlay_testi
{
    background-image: linear-gradient( #f6d6d6e8, #f6d6d6e8);
    top:0%;
    width:100%;
    height:100%;
    position:absolute;
    z-index: -1;
    padding: 38px 0px;
    
}

/* third part */

.fullscreen-container{
    overflow:hidden;
    width:100%;
    height:100%;
}
#all-pages{
    position:relative;
    top:0;
    transition:all 800ms ease;
    /* height set by js */
}
.page{
    height:100%;
    width:100%;
}
#all-pages .page:nth-child(1){
    /* background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
               url(https://raw.githubusercontent.com/Juuggo/FELab/master/fullscreen-scroll/images/bg1.jpg)
               center / cover; */
               background-image: linear-gradient( #F6D6D6, #F6D6D6 );
}
#all-pages .page:nth-child(2){
    /* background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
               url(https://raw.githubusercontent.com/Juuggo/FELab/master/fullscreen-scroll/images/bg2.jpg)
               center / cover; */
               background-image: linear-gradient( #C9E8DB, #C9F4E2 );
}
#all-pages .page:nth-child(3){
    /* background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
               url(https://raw.githubusercontent.com/Juuggo/FELab/master/fullscreen-scroll/images/bg3.jpg)
               center / cover; */
               background-image: linear-gradient( #C3D8F6, #CFE3FF );
}
#all-pages .page:nth-child(4){
    /* background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
               url(https://raw.githubusercontent.com/Juuggo/FELab/master/fullscreen-scroll/images/bg3.jpg)
               center / cover; */
               background-image: linear-gradient( #C9E8DB, #C9F4E2 );
}
#all-pages .page:nth-child(5){
    /* background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
               url(https://raw.githubusercontent.com/Juuggo/FELab/master/fullscreen-scroll/images/bg3.jpg)
               center / cover; */
               background-image: linear-gradient( #C3D8F6, #CFE3FF );
}
#all-pages .page:nth-child(6){
    /* background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
               url(https://raw.githubusercontent.com/Juuggo/FELab/master/fullscreen-scroll/images/bg3.jpg)
               center / cover; */
               background-image: linear-gradient( #F6D6D6, #F6D6D6 );
}
.text-container{
    /* font-family: sans-serif, helvetica, arial; */
    color:white;
    text-align:justify;
    position:relative;
    top:50%;
    margin:0 40px;
    opacity:0;
    visibility:visible;
    transform:translateY(-50%);
    transition:all 3s ease;
}
.text-container.in-sight{
    opacity:1;
    visibility:visible;
}
.text-container h2{
    /* font-size:6vh; */
    text-transform:uppercase;
    color:#A00;
    font-weight: 600;
}
.inner_heading
{
    padding-bottom: 20px;
}
.text-container p{
    /* font-size:2vh; */
    /* padding:1em 0; */
    color: #6a6a6a;
    line-height: 1.5;
    word-spacing: 5px;
    letter-spacing: 1.3px;
    text-align: justify;
}
.nav-dot-container{
    position:fixed;
    top:50%;
    right:20px;
    transform:translateY(-50%);
}
.nav-dot{
    width:20px;
    height:30px;
    padding:20px 0px;
    margin:auto;
}
.nav-dot span{
    display:block;
    width:10px;
    height:10px;
    border-radius:50%;
    background:#fff;
    transition:all 200ms ease;
    margin: auto;
}
.nav-dot span:hover{
    width:12px;
    height:12px;
}
.nav-dot.dot-active span{
    width:15px;
    height:15px;
    /* color:#a00; */
}
/* vertical carosuel */
/* .owl-carousel-vertical
{
    transform: rotate3d(0, 0, 1, -90deg);

}
.item
{
    transform: rotate3d(0, 0, 1, 90deg);

} */
.contact_bg
{
    background-color: #faf2f2;
    height: 50vh;
    width: 100%;
    clip-path: polygon(100% 0%, -100% 100%, 100% 100%);
    position: absolute;
    top: 141%;
    z-index: -1;
}
@media only screen and (max-width:1440px){
    .contact_bg{
    background-color: #faf2f2;
    height: 90vh;
    width: 100%;
    clip-path: polygon(100% 0%, -100% 100%, 100% 100%);
    position: absolute;
    top: 141%;
    z-index: -1;
}
}
.app_align
{
    font-weight: 700;
    color:#000 !important;
}
.port_owl,.owl-item img
{
    /* margin-top: 0px !important; */
}

  /* .wapper
  {
      width:100%;
      height: 100%;
  } */
/* animation */
.about_img_align1
{
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 14%;
}
.ser-float 
{
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top:-8%
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}
.header-scrolled
{
    background-color: rgb(255, 255, 255);
    
    box-shadow: 0px 0px 5px 5px #00000040;
}
.paddi_size_20
{
    padding: 0px 30px;
}
.testi_title
{
    font-size: 16px;
    color:#A00;
    font-weight: 700;
    text-align: center;
}
.testi_title span
{
    color: #505050;
}
.font-size-14
{
    font-size: 14px;
    /* height: 25vh; */
}
.about-padding
{
    padding: 100px 0px 100px;
}
.menu_bg ul li a.active
{
    /* color: #A00; */
}
  .gooey{
    /* background-image: linear-gradient(120deg, #34e0f0 0%, #b400ff 100%); */
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    width: 180px; height: 144px;
    animation: morph 3s linear infinite; 
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    will-change: border-radius;
    position: absolute;
    z-index: -1;
  }
  .gooey:before,
  .gooey:after{
    content: '';
    width: 260%;
    height: 251%;
    display: block;
    position: absolute;
    left: 30px; top: 30px;
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    box-shadow: 5px 5px 89px rgb(0 102 255 / 12%);
    will-change: border-radius, transform, opacity;
    animation-delay: 200ms;
    background-image: linear-gradient(120deg,#F6D6D6 53%, #F6D6D6 100%);
    z-index: -1;
  }
  
  .gooey:before{
    animation: morph 3s linear infinite;
    opacity: .21;
    animation-duration: 1.5s;
  }
  
  .gooey:after{
    animation: morph 3s linear infinite;
    animation-delay: 400ms;
    opacity: .89;
    line-height: 120px;
    text-indent: -21px;
  }
  .test,.owl-item img
  {
      /* margin-top: -10px !important; */
  }
  
  @keyframes morph{
    0%,100%{
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
      transform: translate3d(0,0,0) rotateZ(0.01deg);
    }
    34%{
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
      transform:  translate3d(0,5px,0) rotateZ(0.01deg);
    }
    50%{
      opacity: .89;
      transform: translate3d(0,0,0) rotateZ(0.01deg);
    }
    67%{
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
      transform: translate3d(0,-3px,0) rotateZ(0.01deg);
    }
  }
  
  @keyframes fadeIn{
    100%{
      transform: scale(1.03);
      opacity: 0;
    }
}
.ser_sidebg
{
    background-image: url("./assets/img/subbg.png");
        background-position: top right;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
/* footer */
.footer_align h6
{
    font-weight: 600;
    color:#fff;
}
.footer_align ul li
{
    font-size: 14px;
    padding: 8px 0px;
}
.footer_align ul li a
{
    color:#ccc;
}
.footer_align ul li a:hover
{
    color:#A00;
}
.address_align
{
    line-height: 2.0 ;
}

.footer_sec{
    background-color:#181818;
    color:#ccc;
}
.foot_icon
{
    padding-right:15px !important ;
    font-size: 20px !important;
}
.foot_icon:hover
{
    color:#A00;
}
.footer_hr
{
    margin:unset !important;
}
.copyright , .bytagtes
{
    font-size: 12px;
}
.bytagtes span a
{
    color:#A00;
}
.owl-carousel .owl-stage-outer
{
    padding-bottom: 20px;
}
/* .sec_align_ha
{
    margin-top: 40px;
} */
/* testjs */
.well {
	position: relative;
	overflow: hidden;
	transition: 1s cubic-bezier(0.5, 0, 0.5, 1);
}

.panel {
	position: relative;
	height: 100vh;
	overflow: hidden;
	/* font-size: 10vmin; */
	padding: 1em;
	box-sizing: border-box;
	/* color: hsla(0, 0%, 100%, .1); */
	cursor: default;
}

.panel:nth-child(1) {
	background-image: linear-gradient(to right, #F6D6D6, #F6D6D6 );
}

.panel:nth-child(3) {
	background-image: linear-gradient(to right, #C9E8DB, #C9F4E2 );
}

.panel:nth-child(5) {
	background-image: linear-gradient(to right, #C3D8F6, #CFE3FF );
}

.panel:nth-child(7) {
	background-image: linear-gradient(to right, #E6DDF4, #EEE5FD );
}

.panel:nth-child(9) {
	background-image: linear-gradient(to right, #C9E8DB, #C9F4E2 );
}
.panel:nth-child(11) {
	background-image: linear-gradient(to right, #F6D6D6, #F6D6D6 );
}
.pro_head
{
    color :#AA0000;
    font-weight:600;
}
.height_set
{
    height: 90vh;
}
.banner_height_set
{
    height: 100vh;
}
.active
{
    color:#A00 !important;
}
.carousel-indicators
{
    z-index:0 !important;
}
.carousel-control-prev,.carousel-control-next
{
    display: none;
}
.navbar-toggler
{
    padding: 0px;
}
.banner_owl .owl-dots
{
    margin-top:-70px
}
.port_owl .owl-dots
{
    display: none;
}
.port_owl .owl-nav
{
    display: unset !important;
}
.service_owl .owl-dots
{
    margin-top: -60px;
}
.ser_height_set
{
    height:90vh;
}
.con_align {
    margin-top: 100px;
}
.pointer
{
    cursor: pointer;
}
.nav {
    border-right: 0px;
}
.nav-pills .nav-link {
    border-radius: 0px;
    border-right: 3px solid #fff;
}
.btn:hover {
    color: #fff;
}
.btn:focus {
    outline: 0;
    box-shadow: none;
}
.btn-primary:hover {
    color: #fff;
    background-color: #aa0000;
    border-color: #aa0000;
}
.owl-theme .owl-dots .owl-dot span {
    background: #fff !important;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.form-control{
    border: none;
}
.contact_address {
    background: #fff;
    min-height: 190px;
    box-shadow: 0px 0px 8px #aaaaaa;
    border-radius: 5px;
}
.contact_head{
    padding: 15px;
}
.contact_head h3{
    font-weight: 500;
    color: #aa0000;
    font-size: 20px;
    line-height: 0px;
    font-weight: bold;
}
.contact_icon{
    font-size: 60px;
    text-align: center;
}
.address_cont{
    font-weight: 400;
}
.side_info {
    position: relative;
    right: 40px;
    z-index: 1;
}


.serviceBox{
    padding-bottom: 40px;
    margin-top: 10px;
    position: relative;
}
/* .serviceBox:after{
    content: "";
    width: 100%;
    height: 10px;
    background: transparent;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.5s ease 0s;
} */
.serviceBox:hover:after{
    border-color: #eb959e;
}
.serviceBox .service-icon{
    font-size: 67px;
    color: rgb(170, 0, 0);
    line-height: 0;
    margin: 0 0 30px 5px;
    opacity: 0.15;
}
.serviceBox:hover .service-icon{
    animation: 0.3s ease 0s normal forwards 1 running loading;
}
.serviceBox .title{
    font-size: 22px;
    font-weight: bold;
    color: #4c4c4c;
    margin: 0 0 25px 0;
    text-transform: capitalize;
}
.serviceBox .service-no{
    display: inline-block;
    float: right;
    font-size: 32px;
    color: #ddd;
    line-height: 28px;
}
.serviceBox .description{
    font-size: 16px;
    color: #8f8f8f;
    margin: 0;
}
@keyframes loading{
    49%{ transform: translateY(-100%); }
    50%{
        opacity: 0;
        transform: translateY(100%);
    }
    51%{ opacity: 1; }
}
@media only screen and (max-width:990px){
    .serviceBox{ margin-bottom: 40px; }
}
.why-select{
    box-shadow: inset 0 0 10px #0000003f;
    padding: 30px;
    background-color: #fff5ee;
}
.section-Head{
    margin: 0px auto;
}
.sub-Head{
    font-weight: 600;
}
.section-Head::after{
    width: 50%;
    height: 2px;
    border-radius: 50%;
    background-color: #000;
    position: relative;
}
.sub-top{
    font-weight: 600;
    color: #a00;
}
.about_content{
    background-color: #fff3eb;
    padding: 40px;
}
.section_title{
    font-size: 35px;
    color: #aa0000;
    font-weight: 400;
}
.section_title span {
    font-size: 50px;
    font-weight: 600;
}
.about-content {
    padding-right: 58;
    line-height: 28px;
    color: #333;
    font-size: 16px !important;
}
.about-border {
    border: 4px solid #ffd5ba;
    padding: 20px;
}
a:hover,
a:focus{
    text-decoration: none;
    outline: none;
}
.vertical-tab{
    background: linear-gradient(#fff,#f5f5f5,#fff);
    font-family: 'Poppins', sans-serif;
    display: table;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
.vertical-tab .nav-tabs{
    background-color: transparent;
    display: table-cell;
    width: 25%;
    min-width: 25%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    vertical-align: top;
}
.vertical-tab .nav-tabs li{ float: none; }
.vertical-tab .nav-tabs li a{
    color: #000;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    padding: 12px 10px 10px;
    margin: 0 0 10px;
    border-radius: 10px;
    border: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease 0.1s;
}
.vertical-tab .nav-tabs li a:hover,
.vertical-tab .nav-tabs li.active a,
.vertical-tab .nav-tabs li.active a:hover{
    color: #fff !important;
    background: #2e86de;
    border: none;
    box-shadow: 0 0 10px -4px #2e86de;
    background: linear-gradient(135deg, #fdae82 0%,#ff3a56 100%);
}
.vertical-tab .nav-tabs li a:before{
    content: '';
    /* background-color: #2e86de; */
    height: 100%;
    width: 0;
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    transition: all 0.4s ease 0s;
    background: linear-gradient(135deg, #fdae82 0%,#ff3a56 100%);
}
.vertical-tab .nav-tabs li a:hover:before,
.vertical-tab .nav-tabs li.active a:before,
.vertical-tab .nav-tabs li.active a:hover:before{
    width: 100%;
    opacity: 0;
    background: linear-gradient(135deg, #fdae82 0%,#ff3a56 100%);
}
.vertical-tab .tab-content{
    color: #888;
    background: transparent;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 21px;
    padding: 15px 15px 10px 15px;
    display: table-cell;
}
.vertical-tab .tab-content h3{
    color: #aa0000;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 4px;
}
@media only screen and (max-width: 479px){
    .vertical-tab .nav-tabs{
        width: 100%;
        display: block;
    }
    .vertical-tab .nav-tabs li:last-child a{ margin: 0; }
    .vertical-tab .tab-content{
        font-size: 14px;
        display: block;
    }
}
.serviceAlign{
    margin: 0px auto;
}
.vertical-tab .tab-content p{
    color: #000;
}
.service_bg{
    background-color: #27272e;
}
.section-heading .subtitle {
    color: #a71307;
    font-weight: 500;
    font-size: 20px;
    font-family: DM Sans,sans-serif;
    font-family: var(--font-primary);
    margin-bottom: 15px;
}
.service .section-heading .title {
    color: #fff;
    margin-bottom: 35px;
    width: 60%;
    font-size: 60px;
}
.about-us .section-heading .title {
    color: #000;
    width: 100%;
    font-size: 60px;
}
.h-400{
    height: 400px !important;
}
.portfolio .section-heading .title {
    color: #000;
    width: 70%;
    font-size: 60px;
}
.about-us .section-heading p {
    width: 100%;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 28px;
}
.services-grid {
    display: flex;
    padding: 50px 50px 40px;
    border-radius: 30px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
    transition: var(--transition);
    height: 420px;
}
.services-grid .content p {
    color: #fff;
    opacity: .5;
    letter-spacing: 1px;
    line-height: 28px;
    text-align: justify;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.services-grid .content .title a {
    color: #fff;
}
.services-grid .content .title{
    margin-bottom: 25px;
    color: #fff;
}
.services-grid .content p{
    margin-bottom: 35px;
    /* text-align: justify; */
}
.services-grid:after {
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg,#27272e,#303035);
    background: var(--gradient-dark);
    border: 1px solid;
    border-image-source: linear-gradient(0deg,#27272e 64.45%,rgba(74,75,86,.72));
    border-radius: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.services-grid .content .more-btn:before {
    content: "";
    width: 22px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 65%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.services-grid .content .more-btn {
    font-weight: 500;
    color: #fff;
    position: relative;
    padding-left: 26px;
}
.services .services-grid.active:after, .services-grid:hover:after {
    visibility: visible;
    opacity: 1;
}
.services-grid:after {
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg,#27272e,#303035);
    border: 1px solid;
    border-image-source: linear-gradient(0deg,#27272e 64.45%,rgba(74,75,86,.72));
    border-radius: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.services-grid .thumbnail {
    margin-right: 25px;
    max-width: 85px;
}
.services-grid.active .content p, .services-grid:hover .content p {
    color: #ecf2f6;
    opacity: 1;
}
.services-grid.active .content .more-btn, .services-grid:hover .content .more-btn {
    color: #fab8c4;
}
.services-grid.active .content .more-btn:before, .services-grid:hover .content .more-btn:before {
    background-color: #fab8c4;
}
.product .section-heading .title {
    color: #000;
    width: 60%;
    font-size: 60px;
}
@media only screen and (max-width: 1199px){
    .service .section-heading .title {
        color: #fff;
        margin-bottom: 35px;
        width: 70%;
    }
    .product .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 70%;
    }
    .portfolio .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 70%;
    }
    .about-us .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 100%;
    }}
@media only screen and (max-width: 768px){
    .service .section-heading .title {
        color: #fff;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    .product .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    .portfolio .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    .about-us .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }}
@media only screen and (max-width: 425px){
    .service .section-heading .title {
        color: #fff;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    .product .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    .portfolio .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    .about-us .section-heading .title {
        color: #000;
        margin-bottom: 35px;
        width: 90%;
        font-size: 30px
    }
    }
.shape-group-10 .shape.shape-1 {
    top: 100px;
    right: 0;
}
.shape-group-10 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-10 .shape.shape-2 {
    bottom: 200px;
    left: 0;
}
.shape-group-10 .shape.shape-3 {
    top: 680px;
    left: 110px;
}
.section {
    position: relative;
    z-index: 1;
}
.bg-color-light {
    background-color: #fff8f3b5;
}
.section-heading .subtitle {
    color: #a71307;
    font-weight: 500;
    font-size: 20px;
    font-family: DM Sans,sans-serif;
    font-family: var(--font-primary);
    margin-bottom: 15px;
}
.mb--40 {
    margin-bottom: 40px!important;
}
.section-padding-equal {
    padding: 140px 0;
}
.shape-group-6 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-6 .shape.shape-1 {
    top: 0;
    left: 0;
}
.shape-group-6 .shape.shape-2 {
    top: 110px;
    left: 0;
}
.shape-group-6 .shape.shape-3 {
    bottom: 140px;
    right: 0;
}
.about_overlay {
    background-color: #00000059;
    border-radius: 10px;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
}
.project-grid {
    box-shadow: 0 55px 86px -35px #ecf2f6;
    border-radius: 30px;
    margin-bottom: 40px;
    width: 80%;
}
@media only screen and (max-width: 768px){
    .project-grid {
        box-shadow: 0 55px 86px -35px #ecf2f6;
        border-radius: 30px;
        margin-bottom: 40px;
        width: 60%;
    } 
}
@media only screen and (max-width: 425px){
    .project-grid {
        box-shadow: 0 55px 86px -35px #ecf2f6;
        border-radius: 30px;
        margin-bottom: 40px;
        width: 100%;
    } 
}
.project-grid .thumbnail {
    overflow: hidden;
    border-radius: 30px 30px 0 0;
    position: relative;
    background-color: #fff;
}

.project-grid .content {
    background-color: #fff;
    border-radius: 0 0 30px 30px;
    padding: 20px 12px;
    height: 130px;
}
.project-grid .content .title {
    letter-spacing: -.045em;
    margin-bottom: 10px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.project-grid .content .subtitle span {
    margin-right: 5px;
    position: relative;
    font-size: 14px;
    color: #000000a1;
}
.project-grid .thumbnail img {
    border-radius: 30px 30px 0 0;
    width: 100%;
    transition: all .3s ease-in-out;
    transition: var(--transition);
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: -webkit-transform 2s cubic-bezier(.2,.96,.34,1);
    transition: transform 2s cubic-bezier(.2,.96,.34,1);
    transition: transform 2s cubic-bezier(.2,.96,.34,1),-webkit-transform 2s cubic-bezier(.2,.96,.34,1);
}
.project-grid:hover .thumbnail img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.shape-group-7 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-7 .shape.shape-1 {
    top: 90px;
    right: 200px;
    opacity: 0.25;
}
.shape-group-7 .shape.shape-2 {
    top: 210px;
    left: 52%;
    opacity: 0.25;
}
.shape-group-7 .shape.shape-3 {
    top: 40%;
    left: 170px;
    opacity: 0.25;
}
.pages .product-grid.service-style-2{
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 500px;
}
.product-grid.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 420px;
}
.select-box .product-grid.service-style-2{
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 400px;
}
.product-grid.page.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 480px;
}
.product-grid.page.career-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 480px;
}
.product-grid.page.career-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 530px;
}
.product-grid.service-style-2 .content .cont{
    color: #525260;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
.product-grid.service-style-2 .content .select{
    color: #525260;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
.product-grid.service-style-2 .select-box{
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 450px;
}
@media only screen and (max-width: 1440px){
    .select-box .product-grid.service-style-2 {
        height: 500px;
    }
}
@media only screen and (max-width: 1024px){
    .select-box .product-grid.service-style-2 {
        height: 430px;
    }
    .product-grid.page.service-style-2{
        height: 600px !important;
    }
    .product-grid.page.career-style-2{
        height: 620px !important;
    }
    .pages .product-grid.service-style-2{
        height: 450px !important;
    }
}
@media only screen and (max-width: 768px){
.select-box .product-grid.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 360px;
}
.pages .product-grid.service-style-2{
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 700px !important;
}
.product-grid.page.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: auto !important;
}
.product-grid.page.career-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: auto !important;
}

.product-grid.service-style-2{
    height: auto;
}
.h-400{
    height: auto !important;
}
}
@media only screen and (max-width: 767px){
    .product-grid.service-style-2 {
        background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
        border: 1px solid;
        border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
        background-color: #fff;
        margin-bottom: 30px;
        height: auto;
    }
    .product-grid.page.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: auto;
}
.product-grid.page.career-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: auto;
}
.pages .product-grid.service-style-2{
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: auto !important;
}
.select-box .product-grid.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: auto;
}
.services-grid {
    display: flex;
    padding: 50px 50px 40px;
    border-radius: 30px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
    transition: var(--transition);
    height: auto;
}
}
.product-grid .thumbnail {
    margin-right: 25px;
    max-width: 85px;
}
.product-grid .content {
    flex: 1 1;
}
.product-grid .content .title{
    padding-bottom: 25px;
    font-weight: bold;
}
.product-grid.service-style-2.active, .product-grid.service-style-2, .product-grid.career-style-2:hover {
    background-color: #ecf2f6;
}
.product-grid .service-style-2 .active .content .more-btn, .product-grid.service-style-2:hover .content .more-btn {
    color: #5956e9;
}
.product-grid.service-style-2.active .content .more-btn:before, .product-grid.service-style-2:hover .content .more-btn:before {
    background-color: #5956e9;
}
.product-grid {
    display: flex;
    padding: 50px 50px 40px;
    border-radius: 30px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.product-grid .thumbnail {
    margin-right: 25px;
    max-width: 85px;
}
.product-grid.service-style-2 .content p {
    color: #525260;
    opacity: 1;
    letter-spacing: 1px;
    line-height: 28px;
    text-align: justify;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    display: -webkit-box;
    /* -webkit-line-clamp: 3; */
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
.services-grid.service-style-2 .content .title {
    color: #292930;
}
.mt--200 {
    margin-top: 200px;
}
.method-grid.service-style-2 {
    background: linear-gradient(180deg,#f8f8f8,hsla(0,0%,100%,0));
    border: 1px solid;
    border-image-source: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    background-color: #fff;
    margin-bottom: 30px;
    height: 100%;
}
.method-grid {
    display: flex;
    padding: 50px 50px 40px;
    border-radius: 30px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.method-grid .thumbnail {
    max-width: 85px;
}
.method-grid .content {
    flex: 1 1;
}
.method-grid.service-style-2 .content ul li {
    color: #525260;
    color: var(--color-body);
    opacity: 1;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 35px;
}
.services-grid .content ul li {
    color: #fff;
    opacity: .5;
    letter-spacing: -.025em;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.gradient{
    background: #fffaf6; 
    background: -moz-linear-gradient(top,  #fffaf6 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top,  #fffaf6 0%,#ffffff 100%);
    background: linear-gradient(to bottom,  #fffaf6 0%,#ffffff 100%); 
}
.serviceBox{
    padding-bottom: 40px;
    position: relative;
}
/* .serviceBox:after{
    content: "";
    width: 100%;
    height: 10px;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.5s ease 0s;
} */
.serviceBox:hover:after{ border-color: #ef4789; }
.serviceBox .icon-select1 {
    display: block;
    font-size: 27px;
    color: #279C03;
    text-align: left;
    margin-bottom: 25px;
    width: 10%;
}
.serviceBox .icon-select2 {
    display: block;
    font-size: 27px;
    color: #076678;
    text-align: left;
    margin-bottom: 25px;
    width: 10%;
}
.serviceBox .icon-select3 {
    display: block;
    font-size: 27px;
    color: #a00000;
    text-align: left;
    margin-bottom: 25px;
    width: 10%;
}
.serviceBox .icon-select4 {
    display: block;
    font-size: 27px;
    color: #38c695;
    text-align: left;
    margin-bottom: 25px;
    width: 10%;
}
.serviceBox .icon-select5 {
    display: block;
    font-size: 27px;
    color: #b27cf5;
    text-align: left;
    margin-bottom: 20px;
    width: 8%;
}
.serviceBox .icon-select6 {
    display: block;
    font-size: 27px;
    color: #feb960;
    text-align: left;
    margin-bottom: 19px;
    width: 7%;
}
.serviceBox:hover  .icon-select{ animation: 0.3s ease 0s normal forwards 1 running loading; }
.serviceBox .title1{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: #279C03;
    text-transform: capitalize;
    margin: 0 0 35px 0;
    float: left;
}
.serviceBox .title2{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: #076678;
    text-transform: capitalize;
    margin: 0 0 35px 0;
    float: left;
}
.serviceBox .title3{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: #a00000;
    text-transform: capitalize;
    margin: 0 0 35px 0;
    float: left;
}
.serviceBox .title4{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: #38c695;
    text-transform: capitalize;
    margin: 0 0 35px 0;
    float: left;
}
.serviceBox .title5{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: #b27cf5;
    text-transform: capitalize;
    margin: 0 0 35px 0;
    float: left;
}
.serviceBox .title6{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color: #feb960;
    text-transform: capitalize;
    margin: 0 0 35px 0;
    float: left;
}
.serviceBox .number{
    display: inline-block;
    float: right;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #d6d6d6;
}
.serviceBox .description{
    font-size: 16px;
    color: #8b8a8a;
    margin: 0;
    clear: both;
}
@keyframes loading{
    49%{
        transform: translateY(-100%);
    }
    50%{
        opacity: 0;
        transform: translateY(100%);
    }
    51%{
        opacity: 1;
    }
}
@media only screen and (max-width:990px){
    .serviceBox{ margin-bottom: 20px; }
}
.bg-blue{
    background-color: #ecf2f6;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}
.shape-group-21 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-21 .shape.shape-1 {
    top: 0;
    left: 0;
}
.shape-group-21 .shape.shape-2 {
    top: 200px;
    right: 200px;
    text-align: right;
}
.shape-group-21 .shape.shape-3 {
    right: 100px;
    top: 45%;
    height: 53px;
    width: 53px;
}
.shape-group-21 .shape.shape-4 {
    left: 220px;
    top: 45%;
    height: 20px;
    width: 20px;
    -webkit-animation: movingleftright1 25s infinite;
    animation: movingleftright1 25s infinite;
}
.shape-group-21 .shape.shape-5 {
    left: 45%;
    bottom: 130px;
    height: 20px;
    width: 20px;
    -webkit-animation: movingleftright3 20s infinite;
    animation: movingleftright3 20s infinite;
}
.shape-group-21 .shape.shape-6 {
    right: 230px;
    bottom: 40%;
    height: 20px;
    width: 20px;
}
.shape-group-21 .shape.shape-7 {
    right: 250px;
    top: 40%;
    height: 20px;
    width: 20px;
}
.brand-grid img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .3;
    transition: all .3s ease-in-out;
    transition: var(--transition);
}
.brand-grid {
    padding: 10px 20px;
}
.brand-grid.active img, .brand-grid:hover img {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}
.pb--70 {
    padding-bottom: 70px;
}
.section-padding {
    padding: 140px 0 110px;
}
.section {
    position: relative;
    z-index: 1;
}
.bg-color-light {
    background-color: #ecf2f6;
}
.process-work {
    display: flex;
    align-items: center;
    margin-bottom: -20px;
}
.process-work .thumbnail {
    background-color: #fff;
    border-radius: 30px;
    text-align: center;
}
.process-work .content {
    flex: 1 1;
    margin-left: 20px;
}
.process-work .content .title {
    font-size: 40px;
    color: #a71307;
}
.process-work .content .titles {
    font-size: 40px;
    color: #292930 !important;
}
.process-work .content p {
    color: #737387;
    width: 60%;
    margin-bottom: 0;
    line-height: 28px;
    letter-spacing: 1px;
}
.process-work .content-reverse .content p {
    color: #737387;
    width: 60%;
    margin-bottom: 0;
}
.cont-reverse {
    width: 100% !important;
    padding-left: 150px;
}
.process-work.content-reverse {
    flex-direction: row-reverse;
}
.process-work.content-reverse .content {
    margin: 0 60px 0 0;
    text-align: right;
    /* padding-left: 300px; */
}
@media only screen and (max-width: 1199px){
.process-work.content-reverse .content {
    padding-left: 0;
    margin-left: -20px;
}}
@media only screen and (max-width: 767px){
.process-work {
    display: block;
    margin-bottom: 50px !important;
}
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}
.shape-group-17 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-17 .shape.shape-1 {
    top: 0;
    left: 0;
}
.shape-group-17 .shape.shape-2 {
    top: 360px;
    right: 0;
}
.shape-group-17 .shape.shape-3 {
    top: 430px;
    left: 0;
}
.shape-group-17 .shape.shape-4 {
    top: 35%;
    right: 0;
}
.shape-group-17 .shape.shape-5 {
    top: 56%;
    left: 0;
}
.shape-group-17 .shape.shape-6 {
    top: 75%;
    right: 0;
}
.section-padding {
    padding: 140px 0 110px;
}
.contact-form-box.shadow-box {
    box-shadow: 0 20px 48px 0 rgb(153 161 170 / 25%);
}
.mb--30 {
    margin-bottom: 30px!important;
}
.contact-form-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
}
.contact-form-box .title {
    font-size: 40px;
}
.axil-btn.btn-fluid {
    width: 100%;
}
.axil-btn.btn-fill-primary {
    border: 1px solid #5956e9;
    overflow: hidden;
}
.axil-btn.btn-borderd:hover, .axil-btn.btn-fill-primary {
    background-color: #5956e9;
    color: #fff;
}
.contact-form-box .form-group .axil-btn:after {
    width: calc(100% + 50px);
}
.axil-btn.btn-fill-primary:after {
    content: "";
    height: 300px;
    width: 300px;
    background-color: #2522ba;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: .5s cubic-bezier(.25,.46,.45,.94);
    z-index: -1;
}
.form-control, input {
    border: 1px solid #ecf2f6;
    border-radius: 16px;
    background-color: transparent;
    color: #292930;
    padding: 15px 20px;
    width: 100%;
}
.form-group label {
    font-size: 14px;
    font-weight: 400;
    color: #27272e;
    margin-bottom: 10px;
}
.form-group {
    margin-bottom: 16px;
}
.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: all .3s ease-in-out;
    transition: var(--transition);
    position: relative;
    z-index: 1;
}
.mb--100 {
    margin-bottom: 100px;
}
.mt--150 {
    margin-top: 150px;
}
.contact-info {
    background-color: #27272e;
    border-radius: 14px;
    padding: 60px 50px;
    box-shadow: 0 9px 18px 0 rgb(0 0 0 / 25%);
}
.contact-info .title {
    color: #fff;
    margin-bottom: 20px;
}
.contact-info p {
    color: #999fae;
    width: 100%;
}
.contact-info .phone-number {
    font-size: 20px;
    color: #fab8c4 !important;
    margin-bottom: 0;
}
.shape-group-12 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-12 .shape.shape-1 {
    top: 115px;
    left: 38%;
}
.shape-group-12 .shape.shape-2 {
    left: 60px;
    bottom: 130px;
}
.shape-group-12 .shape.shape-3 {
    right: 60px;
    bottom: 50px;
}
.section-padding
{
    padding: 140px 0px 110px 0px;
}
.title_align h2
{
    font-size: 60px;
    font-weight: 200;
    letter-spacing: -.045em;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-bottom: 5px;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 30px;

}
.subtitle
{
    color: #a71307;
    font-weight: 500;
    font-size: 20px;
    font-family: 'DM Sans',sans-serif;
    margin-bottom: 15px;
}
.count-number h2
{
    color: #a71307;
    font-family: 'DM Sans',sans-serif;
    font-size: 42px;
    letter-spacing: -.045em;
    margin-bottom: 0;
    padding: 0 6px;
    align-items: center;
}
.title_align p
{
    margin-bottom: 25px;
    line-height: 28px;
    letter-spacing: 1px;
    text-align: justify;
}
.call-to-action-area {
    padding: 140px 0 0px;
    position: relative;
    z-index: 1;
}
.call-to-action-area:before {
    content: "";
    height: 730px;
    width: 100%;
    background-color: #5956e9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.call-to-action {
    border-bottom: 1px solid #e7e7e7;
}
.call-to-action .section-heading {
    margin-bottom: 65px;
}
.est .section-heading{
    text-align: center;
    margin-bottom: 60px;
}
.call-to-action .section-heading span {
    color: #ffdc60 !important;
}
.est .section-heading .subtitle {
    color: #a71307;
    color: var(--color-rose);
    font-weight: 500;
    font-size: 20px;
    font-family: DM Sans,sans-serif;
    font-family: var(--font-primary);
    margin-bottom: 15px;
}
.call-to-action .section-heading .title {
    width: 60%;
    margin: 0 auto 55px;
    font-size: 64px;
}
.heading-light .title {
    color: #fff;
}
.axil-btn.btn-large {
    padding: 23px 55px;
}
.axil-btn.btn-fill-white {
    background-color: #fff;
    box-shadow: 0 48px 48px 0 rgb(0 0 0 / 12%);
}
.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: all .3s ease-in-out;
    position: relative;
    z-index: 1;
}
.call-to-action .thumbnail {
    position: relative;
    text-align: center;
}
.call-to-action .thumbnail .larg-thumb {
    display: inline-block;
    margin-right: -30px;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    bottom: 0;
    left: 155px;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    bottom: 0;
    right: 80px;
}
.call-to-action .thumbnail .small-thumb li {
    position: absolute;
}
.shape-group-9 .shape.shape-1 {
    top: 0;
    left: 32%;
}
.shape-group-9 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-9 .shape.shape-2 {
    top: 180px;
    left: 18%;
    opacity: .5;
    -webkit-animation: movingleftright1 20s infinite;
    animation: movingleftright1 20s infinite;
}
.shape-group-9 .shape.shape-3 {
    top: 31%;
    left: 20%;
    -webkit-animation: movingleftright2 25s infinite;
    animation: movingleftright2 25s infinite;
}
.shape-group-9 .shape.shape-4 {
    top: 44%;
    left: 28%;
    -webkit-animation: movingleftright1 25s infinite;
    animation: movingleftright1 25s infinite;
}
.shape-group-9 .shape.shape-5 {
    top: 50%;
    left: 17%;
    -webkit-animation: movingleftright2 30s infinite;
    animation: movingleftright2 30s infinite;
}
.shape-group-9 .shape.shape-6 {
    top: 26%;
    right: 21%;
    -webkit-animation: movingleftright3 30s infinite;
    animation: movingleftright3 30s infinite;
}
.shape-group-9 .shape.shape-7 {
    top: 30%;
    right: 15%;
    -webkit-animation: movingleftright1 30s infinite;
    animation: movingleftright1 30s infinite;
}

@media only screen and (max-width: 1199px){
.call-to-action .thumbnail .small-thumb li img {
    width: 90%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: 15px;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    bottom: 0;
    right: 0px;
}
.call-to-action-area {
    padding: 100px 0;
}}
@media only screen and (max-width: 991px){
.call-to-action .thumbnail .small-thumb li img {
    width: 70%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -50px;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -35px;
}
.call-to-action .thumbnail .larg-thumb {
    margin-right: 0px;
}
.section-heading .subtitle {
    font-size: 18px;
}
.call-to-action .section-heading .title {
    width: 100%;
}
.axil-btn.btn-large {
    padding: 18px 35px;
}
.call-to-action-area:before {
    height: 650px;
}
.call-to-action-area {
    padding: 0px 0;
}}
@media only screen and (max-width: 767px){
.call-to-action .thumbnail .small-thumb li img {
    width: 35%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -70px;
}
.call-to-action .section-heading {
    margin-bottom: 50px;
}
.section-heading .subtitle {
    font-size: 16px;
}
.call-to-action-area:before {
    height: 480px;
}
.call-to-action-area {
    padding: 60px 0 0px;
}
}
@media only screen and (max-width: 575px){
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -125px;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -10px;
}
.call-to-action .thumbnail .larg-thumb {
    margin-right: -10px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 100%;
}
.call-to-action-area:before {
    height: 620px;
}}
.breadcrumb-banner.single-breadcrumb {
    padding: 200px 0 75px;
}
.breadcrumb-banner {
    padding: 280px 0 200px;
}
.breadcrum-area {
    background: linear-gradient(90deg,#ecf2f6,rgba(236,242,246,0) 70.31%);
    padding: 200px 0 100px;
    position: relative;
    z-index: 1;
}
.heading-left {
    text-align: left;
}
.breadcrumb-banner.single-breadcrumb .section-heading .title, .breadcrumb-banner.single-breadcrumb .section-heading p {
    width: 100%;
}
.breadcrumb-banner .section-heading .title {
    color: #000248;
    width: 55%;
    margin-bottom: 40px;
    font-size: 64px;
}
@media only screen and (max-width: 425px){
    .breadcrumb-banner .section-heading .title {
        font-size: 44px;
    }
}
.breadcrumb-banner .section-heading p {
    width: 40%;
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    font-size: 15px;
}
.breadcrumb-banner.single-breadcrumb .section-heading .title, .breadcrumb-banner.single-breadcrumb .section-heading p {
    width: 100%;
}
.heading-left p {
    margin: 0 0 30px;
}
.breadcrumb-banner.single-breadcrumb .banner-thumbnail {
    position: static;
}
.breadcrumb-banner .banner-thumbnail {
    position: absolute;
    bottom: -100px;
    right: 190px;
    text-align: right;
}
.shape-group-8 .shape {
    position: absolute;
    z-index: -1;
    pointer-events: none;
}
.shape-group-8 .shape.shape-1 {
    bottom: 0;
    left: 0;
}
.shape-group-8 .shape.shape-2 {
    top: 0;
    right: 0;
    text-align: right;
}
.breadcrumb-banner .shape-group-8 .shape.shape-3 {
    bottom: -200px;
    top: auto;
}
.shape-group-8 .shape.shape-3 {
    top: 115px;
    left: 0;
}
.section-heading h3{
    font-size: 48px;
    color: #a71307;
}
.axil-btn .single-btn {
    border: 1px solid #aa0000;
    overflow: hidden;
}
.img-pad{
    padding: 30px;
}
.read_icon {
    font-size: 25px !important;
}
.btn-red{
    background-color: #a00;
}
.popup:hover{
    color: #000;
}
.popup-close{
    border: 0px;
    font-size: 35px;
    background-color: transparent;
    margin-bottom: 15px;
}
.font800{
    font-weight: 800;
}
.file-upload{
    border: 0px;
    padding: 10px 0px;
}
.sm-padding{
    padding: 0px 10px;
}
.floatLeft{
    float: left;
}
.slider {
    /* background: white; */
    height: 150px;
    margin: auto;
    overflow: hidden;
    position: relative;
    /* width: 1140px; */
}

.slider::before, .slider::after {
    /* background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%); */
    content: "";
    height: 100%;
    position: absolute;
    width: 100px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    animation: scroll 160s linear infinite;
    display: flex;
    width: calc(250px * 18);

}

.slider .slide-track:hover {
    animation-play-state: paused;
}

.slider .slide {
    height: 150px;
    width: 150px;
}
.slider .slide-track {
    -webkit-animation: scroll 100s linear infinite;
            animation: scroll 100s linear infinite;
    display: flex;
    width: calc(250px * 40);
  }
/* @keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 50));
    }
} */
@-webkit-keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 20));
    }
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 20));
    }
  }
.ps-timeline-sec {
    position: relative;
    background: #fff;
  }
  .ps-timeline-sec .container {
    position: relative;
  }
  @media screen and (max-width: 767px) {
    .ps-timeline-sec .container ol:before {
      background: #fab8c4;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: 130px !important;
      left: 36px !important;
    }
    .ps-timeline-sec .container ol:after {
      background: #fab8c4;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: inherit !important;
      left: 36px;
      display: none;
    }
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 130px 0 !important;
      border-left: 2px solid #fab8c4;
      padding-left: 0 !important;
      padding-top: 120px !important;
      border-top: 0 !important;
      margin-left: 25px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 220px;
      float: none !important;
      width: inherit !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
      width: 70px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child {
      margin: 0;
      bottom: 0 !important;
      height: 120px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
      bottom: 40px !important;
      width: 40% !important;
      margin-left: 25px !important;
      margin-top: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
      width: 100%;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
      margin-bottom: 0 !important;
      top: -20px;
      width: 50% !important;
      margin-right: 0px;
    }
    .ps-timeline-sec .container ol.ps-timeline li span {
      left: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
      position: absolute !important;
      bottom: 150px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      margin: 0 auto !important;
      width: 80% !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
      position: absolute !important;
      bottom: 180px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li p {
      text-align: right !important;
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -60px;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
  }
  .ps-timeline-sec .container ol:before {
    background: #fab8c4;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    left: 8px;
    top: -3.5px;
  }
  .ps-timeline-sec .container ol:after {
    background: #fab8c4;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    right: 8px;
    top: -3.5px;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 390px 0;
    padding: 0;
    border-top: 2px solid #fab8c4;
    list-style: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 25%;
    padding-top: 30px;
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    background: #fff;
    border: 4px solid #fab8c4;
    border-radius: 50%;
    box-shadow: 0 0 0 0px #fff;
    text-align: center;
    line-height: 50px -10;
    color: #a71307;
    font-size: 24px;
    font-style: normal;
    position: absolute;
    top: -26px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: "";
    color: #fab8c4;
    width: 2px;
    height: 50px;
    background: #fab8c4;
    position: absolute;
    top: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: "";
    color: #fab8c4;
    width: 8px;
    height: 8px;
    background: #fab8c4;
    position: absolute;
    bottom: 90px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: "";
    color: #fab8c4;
    width: 2px;
    height: 50px;
    background: #fab8c4;
    position: absolute;
    bottom: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: "";
    color: #fab8c4;
    width: 8px;
    height: 8px;
    background: #fab8c4;
    position: absolute;
    top: 90px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 130px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    display: table;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    margin-top: 60px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    font-size: 22px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 7px;
    width: 100%;
  }
  input:focus{
    border: 1px solid #ecf2f6 !important; 
  }
  iframe .job-app .wpcf7-form-control input{
    width: 100% !important;
    border: 1px solid #000 !important;
  }
  iframe .job-app .wpcf7-form-control .wpcf7-select{
    width: 100% !important;
    border: 1px solid #000 !important;
  }
  iframe .job-app .wpcf7-form-control .wpcf7-form-control:focus{
    outline: none;
  }
  .textRight{
    text-align: right !important;
  }
 .mb--30 {
    margin-bottom: 30px;
}